import React from 'react';
// import { ChevronDownIcon } from '@heroicons/react/20/solid';
// import { Switch } from '@headlessui/react';

// Icons
import { AtSymbolIcon, PhoneArrowUpRightIcon, BuildingOfficeIcon } from '@heroicons/react/20/solid';

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ');
// }

const ContactUs: React.FC = () => {
    // const [agreed, setAgreed] = useState(false);

    return (
        <div className='contactUsSec'>
            <div className="container mx-auto px-4">
                <div className="max-w-screen-xl mx-auto py-24">
                    {/* Contact Form Section */}
                    <div className="text-center">
                        <h2 className="title">Contact Us</h2>
                        <p className="mt-2 subdesc">
                            We are here to assist you on your study abroad journey.
                        </p>
                    </div>

                    {/* <form action="#" method="POST" className="mt-16 max-w-xl mx-auto">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                                <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-600">
                                    Phone Number
                                </label>
                                <div className="relative mt-2.5">
                                    <div className="absolute inset-y-0 left-0 flex items-center">
                                        <label htmlFor="country" className="sr-only">
                                            Country
                                        </label>
                                        <select
                                            id="country"
                                            name="country"
                                            className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                                        >
                                            <option>US</option>
                                            <option>IND</option>
                                            <option>EU</option>
                                        </select>
                                        <ChevronDownIcon
                                            className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        type="tel"
                                        name="phone-number"
                                        id="phone-number"
                                        autoComplete="tel"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-600">
                                    Message
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        name="message"
                                        id="message"
                                        rows={4}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        defaultValue={''}
                                    />
                                </div>
                            </div>

                            <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2 justify-center">
                                <div className="flex h-6 items-center">
                                    <Switch
                                        checked={agreed}
                                        onChange={setAgreed}
                                        className={classNames(
                                            agreed ? 'bg-primary' : 'bg-gray-200',
                                            'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                                        )}
                                    >
                                        <span className="sr-only">Agree to policies</span>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                agreed ? 'translate-x-3.5' : 'translate-x-0',
                                                'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>
                                </div>
                                <Switch.Label className="text-sm leading-6 text-gray-700">
                                    By selecting this, you agree to allow us to contact you via email.
                                </Switch.Label>
                            </Switch.Group>
                        </div>

                        <div className="mt-10">
                            <button
                                type="submit"
                                className="block w-full rounded-xl bg-primary px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-xl hover:bg-primary-hover focus:bg-primary-hover duration-300 transition-colors"
                            >
                                Let's Talk
                            </button>
                        </div>
                    </form> */}
                </div>

                
                {/* Contact Details Section */}
                <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3 max-w-screen-xl mx-auto mt-16">
                    <div className="p-8 sm:border-r">
                        <div className="max-w-md text-center">
                            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-blue-50 shadow-md">
                                <AtSymbolIcon className='w-8 h-8 text-gray-700' />
                            </div>
                            <h6 className="mb-2 font-semibold leading-5 text-gray-800">Email</h6>
                            <p className="mb-3 text-sm text-gray-700 hover:text-gray-500 hover:no-underline duration-300 transition-colors">
                                <a href="mailto:info@prep4usa.com">
                                    info@prep4usa.com
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="p-8 lg:border-r">
                        <div className="max-w-md text-center">
                            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-blue-50 shadow-md">
                                <PhoneArrowUpRightIcon className='w-8 h-8 text-gray-700' />
                            </div>
                            <h6 className="mb-2 font-semibold leading-5 text-gray-800">Phone</h6>
                            <p className="mb-3 text-sm text-gray-700 hover:text-gray-500 hover:no-underline duration-300 transition-colors">
                                <a href="tel:1234567890">
                                    1234567890
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="p-8 sm:border-r lg:border-r-0">
                        <div className="max-w-md text-center">
                            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-blue-50 shadow-md">
                                <BuildingOfficeIcon className='w-8 h-8 text-gray-700' />
                            </div>
                            <h6 className="mb-2 font-semibold leading-5 text-gray-800">Address</h6>
                            <p className="mb-3 text-sm text-gray-700 hover:text-gray-500 hover:no-underline duration-300 transition-colors">
                                <a href="https://www.google.com" target='_blank' rel="noreferrer">
                                    USA
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;