import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import UserService from '../services/UserService';

const Header: React.FC = () =>  {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    // Check if the user is logged in
    const checkLoginStatus = async () => {
      const loggedIn = await UserService.isUserLoggedIn();
      setIsLoggedIn(loggedIn);
    };

    checkLoginStatus();
  }, []);

  // If the user is logged in, do not render the header
  if (isLoggedIn) {
    return null;
  }

  return (
    <div className="header-container">
      {/* Title and Description */}
      <div className="header-title-container">
        <h1>Prep4USA</h1>
        <p>Your trusted partner from admission to arrival in the USA.</p>
      </div>

      {/* Sliding Box */}
      <div className="header-slider-container">
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
          className="header-swiper"
        >
          <SwiperSlide>
            <div className="header-swiper-slide">
              <h3>Meet Classmates Securely</h3>
              <p>Chat safely with verified alumni and peers, heading to your university soon.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="header-swiper-slide">
              <h3>Your Personal Checklist Guide</h3>
              <p>Easily manage every essential task for studying in the USA.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="header-swiper-slide">
              <h3>Tailored Student Resources</h3>
              <p>Specific guidance crafted for your international student journey in America.</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Header;
