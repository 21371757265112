/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'

//images
// import brandimg from '../assets/preplogo.png'

//icons
import { RiInstagramFill, RiFacebookFill, RiTwitterFill, RiYoutubeFill } from 'react-icons/ri';

const brandicon = require('../assets/preplogo.png');
const Footer: React.FC = () => {
    return (
        <footer className='footerSec'>
            <div className="p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
                <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6 footTop">
                    <div className="md:max-w-md lg:col-span-2">
                        <Link
                            to="/"
                            aria-label="Go home"
                            title="Company"
                            className="inline-flex items-center"
                        >
                            <img className="h-12 w-auto" src={brandicon} alt="preplogo" />
                        </Link>
                        <div className="mt-4 lg:max-w-sm">
                            <p className="text-sm text-gray-700 leading-6">
                                Your trusted guide from admission to arrival in the USA.
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
                        <div className="hidden lg:block"></div>
                        <div>
                            <p className="font-semibold tracking-wide text-gray-800">
                                Category
                            </p>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <Link to="/about"
                                        className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                                    > About US </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy"
                                        className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                                    > Privacy Policy </Link>
                                </li>
                                <li>
                                    <Link to="/terms-condition"
                                        className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                                    > Terms & Condition </Link>
                                </li>
                                {/* <li>
                                    <Link
                                        to="/"
                                        className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                                    >
                                        FAQ
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                        <div>
                            <p className="font-semibold tracking-wide text-gray-800">Contact Details</p>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <a href="mailto:info@prep4usa.com"
                                        className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                                    >info@prep4usa.com</a>
                                </li>
                                {/* <li>
                                    <a
                                        href="tel:+12345677890"
                                        className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                                    >+1 23456 77890</a>
                                </li> */}
                                {/* <li>
                                    <a
                                        href="/checklist#phd"
                                        className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                                    >
                                        PhD
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                        
                        {/* <div>
                            <p className="font-semibold tracking-wide text-gray-800">
                                Testimonials
                            </p>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <HashLink
                                        to="/programs#testimonials" smooth
                                        className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                                    >
                                        Testimonials
                                    </HashLink>
                                </li>
                            </ul>
                        </div> */}
                    
                    </div>
                </div>
                <div className="flex flex-col justify-between pt-5 pb-10 border-t sm:flex-row footBottom">
                    <p className="text-sm text-gray-600">
                        © 2025 Prep4USA. All rights reserved.
                    </p>
                    <div className="flex items-center mt-4 space-x-4 sm:mt-0">
                        <a className="text-gray-500 hover:text-[#3b5998] cursor-pointer text-2xl duration-300 transition-colors">
                            <RiFacebookFill />
                        </a>
                        <a className="ml-4 text-gray-500 hover:text-[#26a7de] cursor-pointer text-2xl duration-300 transition-colors">
                            <RiTwitterFill />
                        </a>
                        <a className="ml-4 text-gray-500 hover:text-[#e2457a] cursor-pointer text-2xl duration-300 transition-colors">
                            <RiInstagramFill />
                        </a>
                        <a className="ml-4 text-gray-500 hover:text-[#CD201F] cursor-pointer text-2xl duration-300 transition-colors">
                            <RiYoutubeFill />
                        </a>
                    </div>
                </div>
            </div>
        </footer>        
    )
}

export default Footer
