import React from 'react'

const PrivacyPolicy: React.FC = () => {
    return (
        // <main className="grid min-h-full place-items-center bg-white px-6 my-20 sm:py-32 lg:px-8"> --original
        <main className="privacyPolicySec">
          <div className="grid min-h-full place-items-center p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
            <h1 className="mt-4 text-4xl font-semibold tracking-tight text-center text-gray-800 sm:text-5xl">Prep4USA Privacy Policy</h1>
            <p className="mt-6 text-base leading-7 text-gray-700">Your privacy matters to us. Prep4USA securely collects and uses personal data solely for providing personalized guidance, resources, and peer connections for international students. We never sell or share your data with third parties without your explicit consent. Your data is securely stored and handled with care.</p>
            <p className="mt-6 text-base leading-7 text-gray-700">By using Prep4USA, you agree to this privacy policy. For any questions or concerns, please contact us directly.</p>
          </div>
      </main>
    )
}

export default PrivacyPolicy