// import React, { useState, useEffect } from 'react';
// import { Dialog, Popover } from '@headlessui/react';
// import { Link, useNavigate } from 'react-router-dom';
// import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
// import { FaArrowRight } from 'react-icons/fa';

// // Images & Firebase Config
// import brandicon from '../assets/preplogo.png';
// import { auth, googleProvider } from '../config/FirebaseConfig';
// import UserService from '../services/UserService';

// // Icons
// import { Bars3Icon } from '@heroicons/react/24/outline';

// const NavBar = () => {
//     const [user, setUser] = useState(null); // Store user data
//     const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
//     const navigate = useNavigate();

//     // Check login status on component mount
//     useEffect(() => {
//         const storedUser = JSON.parse(localStorage.getItem('user'));
//         if (storedUser) {
//             setUser(storedUser); // Restore user from localStorage
//         }
//     }, []);

//     // Handle Google Sign-In
//     const signInWithGoogle = async () => {
//         try {
//             const result = await signInWithPopup(auth, googleProvider);
//             const credential = GoogleAuthProvider.credentialFromResult(result);
//             const token = credential.idToken;

//             const loggedInUser = {
//                 userId: result.user.uid,
//                 displayName: result.user.displayName,
//                 email: result.user.email,
//                 photoURL: result.user.photoURL,
//             };

//             await UserService.signInWithGoogle(token, loggedInUser);
//             setUser(loggedInUser);
//             localStorage.setItem('user', JSON.stringify(loggedInUser));

//             // Redirect to profile page after successful login
//             navigate('/profile');
//         } catch (error) {
//             console.error('Google Sign-In Error:', error);
//         }
//     };

//     // Handle Logout with Refresh
//     const logout = () => {
//         setUser(null);
//         localStorage.removeItem('user');
//         UserService.signOut();
//         navigate('/'); // Redirect to Home
//     };

//     return (
//         <div className='bg-white shadow-md fixed top-0 inset-x-0 z-50'>
//             <header>
//                 <nav className="flex items-center justify-between p-4 lg:px-6" aria-label="Global">
//                     <div className="flex lg:flex-1">
//                         <Link to="/" className="-m-1 p-1">
//                             <img className="h-10 w-auto" src={brandicon} alt="Prep4USA" />
//                         </Link>
//                     </div>
//                     <div className="flex lg:hidden">
//                         <button
//                             type="button"
//                             className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-700"
//                             onClick={() => setMobileMenuOpen(true)}
//                         >
//                             <Bars3Icon className="h-6 w-6" aria-hidden="true" />
//                         </button>
//                     </div>
//                     <Popover.Group className="hidden lg:flex lg:gap-x-8">
//                         <Link to="/" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
//                             Home
//                         </Link>
//                         {user && (
//                             <Link to="/profile" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
//                                 Profile
//                             </Link>
//                         )}
//                         {user && (
//                             <Link to="/community" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
//                                 My Community
//                             </Link>
//                         )}
//                         <Link to="/about" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
//                             About Us
//                         </Link>
//                     </Popover.Group>
//                     <div className="hidden lg:flex lg:flex-1 lg:justify-end">
//                         {user ? (
//                             <button className="btn px-4 py-2 text-sm font-medium" onClick={logout}>
//                                 Logout
//                             </button>
//                         ) : (
//                             <button className="btn px-4 py-2 text-sm font-medium flex items-center gap-2" onClick={signInWithGoogle}>
//                                 Login <FaArrowRight size={12} />
//                             </button>
//                         )}
//                     </div>
//                 </nav>

//                 <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
//                     <Dialog.Panel className="fixed inset-y-0 right-0 w-full bg-white p-6 sm:max-w-sm z-50">
//                         <div className="flex items-center justify-between">
//                             <Link to="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
//                                 <img className="h-10 w-auto" src={brandicon} alt="Prep4USA" />
//                             </Link>
//                             <button
//                                 type="button"
//                                 className="-m-2 inline-flex items-center justify-center rounded-md p-2"
//                                 onClick={() => setMobileMenuOpen(false)}
//                             >
//                                 <Bars3Icon className="h-6 w-6" aria-hidden="true" />
//                             </button>
//                         </div>
//                         <div className="mt-6">
//                             <div className="space-y-2">
//                                 <Link to="/" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
//                                     Home
//                                 </Link>
//                                 {user && (
//                                     <Link to="/profile" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
//                                         Profile
//                                     </Link>
//                                 )}
//                                 <Link to="/about" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
//                                     About Us
//                                 </Link>
//                             </div>
//                             <div className="mt-6">
//                                 {user ? (
//                                     <button className="btn w-full py-2 text-sm font-medium" onClick={logout}>
//                                         Logout
//                                     </button>
//                                 ) : (
//                                     <button className="btn w-full py-2 text-sm font-medium flex items-center gap-2" onClick={signInWithGoogle}>
//                                         Login <FaArrowRight size={12} />
//                                     </button>
//                                 )}
//                             </div>
//                         </div>
//                     </Dialog.Panel>
//                 </Dialog>
//             </header>
//         </div>
//     );
// };

// export default NavBar;

import React, { useState, useEffect } from 'react';
import { Dialog, Popover } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FaArrowRight } from 'react-icons/fa';
import { auth, googleProvider } from '../config/FirebaseConfig';
import UserService from '../services/UserService';
// Icons
import { Bars3Icon } from '@heroicons/react/24/outline';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images & Firebase Config
const brandicon = require('../assets/preplogo.png');


const NavBar: React.FC = () => {
    const [user, setUser] = useState<any>(null); // Store user data
    const [isUniversityVerified, setIsUniversityVerified] = useState<boolean>(false); // Track if the university is verified
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    // Check login status and university verification on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userStores:any =localStorage.getItem('user');
                const storedUser = JSON.parse(userStores);
                console.log(localStorage.getItem('user'))
                if (localStorage.getItem('user')) {
                    setUser(storedUser); // Restore user from localStorage

                    // Fetch user's universities and check verification status
                    const universities = await UserService.getUserUniversities();
                    const isVerified = universities?.some(
                        (uni:any) => uni.userCollegeEmailId && uni.collegeId
                    );
                    setIsUniversityVerified(isVerified);
                }
            } catch (error) {
                console.error('Error fetching user data or university verification:', error);
            }
        };

        fetchUserData();
    }, []);

    // Handle Google Sign-In
    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const credential:any = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.idToken;

            const loggedInUser = {
                userId: result.user.uid,
                displayName: result.user.displayName,
                email: result.user.email,
                photoURL: result.user.photoURL,
            };

            await UserService.signInWithGoogle(token, loggedInUser);
            setUser(loggedInUser);
            localStorage.setItem('user', JSON.stringify(loggedInUser));
            addToast('success','Login Successfully!')
            // Redirect to profile page after successful login
            navigate('/profile');
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };
    const addToast= (type:any,message:any)=>{
        if(type === 'error')
            toast.error(message);
        if(type === 'warning')
            toast.warning(message);
        if(type === 'success')
            toast.success(message);
    };
    // Handle Logout with Refresh
    const logout = async () => {
        await UserService.signOut();
        setUser(null);
        localStorage.removeItem('user');
        addToast('success','Logout Successfully!')
        // navigate('/'); // Redirect to Home
        setTimeout(() => {
            window.location.href = '/'; 
        }, 500);
    };

    return (
        <><ToastContainer /><div className='bg-white shadow-md fixed top-0 inset-x-0 z-50'>
            <header>
                <nav className="flex items-center justify-between p-4 lg:px-6" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1 p-1">
                            <img className="h-10 w-auto" src={brandicon} alt="Prep4USA" />
                        </Link>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <Popover.Group className="hidden lg:flex lg:gap-x-8">
                        <Link to="/" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
                            Home
                        </Link>
                        {user && (
                            <Link to="/profile" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
                                Profile
                            </Link>
                        )}
                        {/* Show Community and Roommates only if logged in and verified */}
                        {user && isUniversityVerified && (
                            <>
                                <Link to="/community" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
                                    My Community
                                </Link>
                            </>
                        )}
                        <Link to="/about" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
                            About Us
                        </Link>
                    </Popover.Group>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        {user ? (
                            <button className="btn px-4 py-2 text-sm font-medium" onClick={logout}>
                                Logout
                            </button>
                        ) : (
                            <button className="btn px-4 py-2 text-sm font-medium flex items-center gap-2" onClick={signInWithGoogle}>
                                Login <FaArrowRight size={12} />
                            </button>
                        )}
                    </div>
                </nav>

                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                    <Dialog.Panel className="fixed inset-y-0 right-0 w-full bg-white p-6 sm:max-w-sm z-50 sideBarMobile">
                        <div className="flex items-center justify-between logHead">
                            <Link to="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
                                <img className="h-10 w-auto" src={brandicon} alt="Prep4USA" />
                            </Link>
                            <button
                                type="button"
                                className="-m-2 inline-flex items-center justify-center rounded-md p-2"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 menuHead">
                            <div className="space-y-2">
                                <Link to="/" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
                                    Home
                                </Link>
                                {user && (
                                    <Link to="/profile" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
                                        Profile
                                    </Link>
                                )}
                                {user && isUniversityVerified && (
                                    <>
                                        <Link to="/community" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
                                            My Community
                                        </Link>
                                        <Link to="/roommates" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
                                            Roommates
                                        </Link>
                                    </>
                                )}
                                <Link to="/about" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
                                    About Us
                                </Link>
                            </div>
                            <div className="mt-6">
                                {user ? (
                                    <button className="btn w-full py-2 text-sm font-medium" onClick={logout}>
                                        Logout
                                    </button>
                                ) : (
                                    <button className="btn w-full py-2 text-sm font-medium flex items-center gap-2" onClick={signInWithGoogle}>
                                        Login <FaArrowRight size={12} />
                                    </button>
                                )}
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
        </div></>
    );
};

export default NavBar;
