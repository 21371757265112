// import React, { useEffect, useState, useRef } from "react";
// import ChatService from "../../services/ChatServices";
// import { useLocation } from "react-router-dom";
// import moment from "moment"; // For formatting timestamps

// const Chat = () => {
//   const location = useLocation();
//   const { chatName, chatType } = location.state || {};
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const chatEndRef = useRef(null); // For scroll-to-bottom functionality

//   const chatId = location.pathname.split("/").pop(); // Extract chat ID from URL

//   useEffect(() => {
//     const fetchMessages = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         if (chatType === "group") {
//           const chatMessages = await ChatService.getGroupMessages(chatId, null);
//           setMessages(chatMessages);
//         } else if (chatType === "personal") {
//           const chatMessages = await ChatService.getPersonalMessages(chatId);
//           setMessages(chatMessages);
//         } else {
//           setError("Invalid chat type.");
//         }
//       } catch (err) {
//         console.error("Error fetching messages:", err);
//         setError("Failed to fetch messages for this chat.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchMessages();
//   }, [chatId, chatType]);

//   useEffect(() => {
//     chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [messages]);

//   const sendMessage = async () => {
//     if (!newMessage.trim()) return;

//     try {
//       const messageData =
//         chatType === "group"
//           ? { content: newMessage, groupId: chatId }
//           : { content: newMessage, recipientId: chatId };

//       const sentMessage = await ChatService.sendMessage(messageData);
//       setMessages((prevMessages) => [...prevMessages, sentMessage]);
//       setNewMessage("");
//     } catch (err) {
//       console.error("Error sending message:", err);
//       setError("Failed to send the message. Please try again.");
//     }
//   };

//   if (loading) return <div>Loading messages...</div>;
//   if (error) return <div className="error-message">{error}</div>;

//   return (
//     <div className="chat-container">
//       <div className="chat-header">
//         <h1>{chatName || "Chat"}</h1>
//         <p>{chatType === "group" ? "Group Chat" : "Private Chat"}</p>
//       </div>

//       <div className="chat-messages">
//         {messages.length === 0 ? (
//           <div className="chat-placeholder">
//             <p>Wow, such empty! Be the first to start the conversation.</p>
//           </div>
//         ) : (
//           messages.map((msg, index) => (
//             <div
//               key={index}
//               className={`chat-message ${msg.sentByThisUser ? "sent" : "received"}`}
//             >
//               <p>{msg.content}</p>
//               <span>{moment(msg.timestamp).format("MMM DD, h:mm A")}</span>
//             </div>
//           ))
//         )}
//         <div ref={chatEndRef} />
//       </div>

//       <div className="chat-input-container">
//         <input
//           type="text"
//           placeholder="Write a reply..."
//           value={newMessage}
//           onChange={(e) => setNewMessage(e.target.value)}
//           className="chat-input"
//         />
//         <button onClick={sendMessage} className="chat-send-button">
//           Send
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Chat;
import React, { useEffect, useState, useRef } from "react";
import ChatService from "../../services/ChatServices";
import { useLocation } from "react-router-dom";
import moment from "moment"; // For formatting timestamps

interface Sender {
  displayName: string;
  photoURL: string;
}

interface Message {
  id: string;
  content: string;
  timestamp: string;
  sentByThisUser: boolean;
  sender?: Sender;
}

// Define types for location state
interface LocationState {
  chatName: string;
  chatType: "group" | "personal";
}

const Chat: React.FC = () => {
  const location = useLocation();
  const { chatName, chatType } = location.state as LocationState; // Dynamic group name
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const chatEndRef = useRef<HTMLDivElement | null>(null); // For scroll-to-bottom functionality

  const chatId = location.pathname.split("/").pop(); // Extract chat ID from URL

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      setError(null);
      try {
        let chatMessages: Message[] = [];
        if (chatType === "group") {
          chatMessages = await ChatService.getGroupMessages(chatId, 0);
        } else if (chatType === "personal") {
          // chatMessages = await ChatService.getPersonalMessages(chatId);
        } else {
          setError("Invalid chat type.");
        }
        setMessages(chatMessages.reverse()); // Ensure chronological order
      } catch (err) {
        console.error("Error fetching messages:", err);
        setError("Failed to fetch messages for this chat.");
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [chatId, chatType]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const messageData =
        chatType === "group"
          ? { content: newMessage, groupId: chatId }
          : { content: newMessage, recipientId: chatId };

      const sentMessage = await ChatService.sendMessage(messageData);
      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setNewMessage("");
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send the message. Please try again.");
    }
  };

  if (loading) return <div>Loading messages...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h1>{chatName || "Chat"}</h1> {/* Dynamically display the group name */}
        <p>{chatType === "group" ? "Group Chat" : "Private Chat"}</p>
      </div>

      <div className="chat-messages">
        {messages.length === 0 ? (
          <div className="chat-placeholder">
            <p>Wow, such empty! Be the first to start the conversation.</p>
          </div>
        ) : (
          messages.map((msg, index) => (
            <div
              key={index}
              className={`chat-message ${msg.sentByThisUser ? "sent" : "received"}`}
            >
              {!msg.sentByThisUser && (
                <div className="message-header">
                  <img
                    src={msg.sender?.photoURL || "/default-avatar.png"} // Default photo fallback
                    alt={msg.sender?.displayName || "User"}
                    className="sender-photo"
                  />
                  <p className="sender-name">{msg.sender?.displayName || "Unknown Sender"}</p>
                </div>
              )}
              <div className="message-content">
                <p>{msg.content}</p>
                <span>{moment(msg.timestamp).format("MMM DD, h:mm A")}</span>
              </div>
            </div>
          ))
        )}
        <div ref={chatEndRef} />
      </div>

      <div className="chat-input-container">
        <input
          type="text"
          placeholder="Write a reply..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="chat-input"
        />
        <button onClick={sendMessage} className="chat-send-button">
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
