import React from 'react';
import { useNavigate } from 'react-router-dom';
const hero = require('../assets/promisebanner.png');

const AboutUs: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/'); // Navigate to the checklist page
  };

  return (
    <section className="overflow-hidden bg-[#DAEFFF] min-h-screen flex flex-col items-center aboutHeader">
      <div className="px-6 py-32 mx-auto sm:px-10 lg:px-20 max-w-3xl lg:max-w-5xl">
        <div className="flex flex-col items-center text-center md:text-left md:items-start space-y-8">
          
          {/* Heading with Extra Spacing */}
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold tracking-tight text-gray-800 mb-8">
            Connect Abroad is built by students, for the students!
          </h1>

          {/* First Paragraph */}
          <p className="text-sm md:text-base text-gray-700 font-medium lg:text-base mb-10">
            Prep4USA is a trusted community built by international students who've successfully navigated the journey you're embarking on. Our mission is simple—to provide clear, personalized guidance, resources, and secure peer connections that empower you to transition confidently from admission to arrival in the USA.
          </p>

          {/* Image Section */}
          <div className="mt-6 w-full flex justify-center">
            <img
              className="lg:w-1/2 object-cover object-center shadow-xl rounded-lg"
              alt="hero"
              src={hero}
            />
          </div>

          {/* Centered Call to Action Button Below Image */}
          <div className="flex justify-center mt-8 w-full">
            <button
              onClick={handleButtonClick}
              className="inline-block px-5 py-4 text-white font-semibold tracking-tight bg-primary hover:bg-primary-hover rounded-lg focus:ring-4 focus:ring-primary transition duration-200"
            >
              Begin Your Journey to USA
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
