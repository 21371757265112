import React from 'react'

const TermsCondition: React.FC = () => {
    return (
        // <main className="grid min-h-full place-items-center bg-white px-6 my-20 sm:py-32 lg:px-8"> --original
        <main className="privacyPolicySec">
          <div className="grid min-h-full place-items-center p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
            <h1 className="mt-4 text-4xl font-semibold tracking-tight text-center text-gray-800 sm:text-5xl">Prep4USA Terms & Conditions</h1>
            <p className="mt-6 text-base leading-7 text-gray-700">By accessing or using Prep4USA, you agree to use this platform responsibly and respectfully. Only verified students admitted to U.S. universities are permitted to access our secure chatrooms and resources.</p>
            <p className="mt-6 text-base leading-7 text-gray-700 text-left">Content provided by Prep4USA is based on the best of our knowledge at the time of publishing; however, we do not guarantee its accuracy, completeness, or timeliness. Immigration laws, university requirements, and procedures frequently change. Users are responsible for verifying information independently.</p>
            <p className="mt-6 text-base leading-7 text-gray-700 text-left">Prep4USA reserves the right to suspend or terminate accounts engaging in inappropriate behavior, harassment, or misuse of our platform.</p>
            <p className="mt-6 text-base leading-7 text-gray-700 text-left">We reserve the right to update these terms as needed. Continued use implies acceptance of any changes.</p>
          </div>
      </main>
    )
}

export default TermsCondition