import React from 'react';
import Header from '../components/Header';

//components
//import Stats from '../components/Stats';
import Checklist from '../components/CheckList';

const Home : React.FC = () => {
  return (
    <div>
      <Header />
      {/* <Stats /> */}
      {/* <Features /> */}
      <div className="checklist-container">
        <Checklist />
      </div>
      {/* <Banner /> */}
    </div>
  );
};

export default Home;
