import axios from 'axios';

class UserService {
  // Base URL for the API
  static API_BASE_URL = 'https://stagingapi.prep4usa.com:8444/pfu-rest-api/api/v1';
  // static API_BASE_URL = 'http://localhost:8080/api/v1';
  
  // Method to send the Google sign-in token to the backend
  static async signInWithGoogle(idToken:any, user:any) {
    try {
      const response = await axios.post(`${this.API_BASE_URL}/user/token?idToken=${idToken}`, user);

      const profileData = {
        userId: response.data.user.userId,
        displayName: response.data.user.displayName,
        email: response.data.user.email,
        gender: response.data.user.gender || "",
        photoURL: response.data.user.photoURL,
        creationTime: response.data.user.creationTime,
        token: response.data.token,
        loggedIn: true,
      };
      localStorage.setItem("profileData", JSON.stringify(profileData));

      console.log('Profile Data:', profileData);

      return true;
    } catch (error) {
      console.error('Error during sign-in:', error);
      return false;
    }
  }

  // Method to add a new university to the user's profile
  static async addUniversity(university:any) {
    try {
      const storedProfileData = localStorage.getItem("profileData");
      if (storedProfileData) {
        const profileData = JSON.parse(storedProfileData);
        console.log('Profile Data:', profileData);
        if (!profileData.universities) {
          profileData.universities = [];
        }
        profileData.universities.push(university);
        localStorage.setItem("profileData", JSON.stringify(profileData));
        console.log('University added:', university);
      }
    } catch (error) {
      console.error('Error adding university:', error);
    }
  }

  // Method to handle user sign-out
  static async signOut() {
    try {
      // await axios.post('/api/signout');
      localStorage.removeItem("profileData");
      console.log('User signed out');
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  }

  // Method to get the current user's profile data from localStorage
  static async getProfileData() {
    try {
      const storedProfileData = localStorage.getItem("profileData");
      if (storedProfileData) {
        const profileData = JSON.parse(storedProfileData);
        console.log('Profile Data:', profileData);
        if (!profileData.universities) {
          profileData.universities = [];
        }
        return profileData;
      } else {
        return null; // No profile data available
      }
    } catch (error) {
      console.error('Error getting profile data:', error);
      throw error;
    }
  }

  // Method to check if the user is logged in
  static async isUserLoggedIn() {
    try {
      const storedProfileData = localStorage.getItem("profileData");
      if (storedProfileData) {
        const profileData = JSON.parse(storedProfileData);
        return profileData && profileData.loggedIn;
      }
      return false;
    } catch (error) {
      console.error('Error checking if user is logged in:', error);
      return false;
    }
  }

  // Method to get the user's token
  static async getUserToken() {
    try {
      const storedProfileData = localStorage.getItem("profileData");
      if (storedProfileData) {
        const profileData = JSON.parse(storedProfileData);
        return profileData && profileData.token;
      }
      return null;
    } catch (error) {
      console.error('Error getting user token:', error);
      return null;
    }
  }

  // Method to get OTP
  static async getOTP(collegeEmail:any) {
    try {
      const response = await axios.post(
        `${this.API_BASE_URL}/user/send_otp?collegeEmail=${collegeEmail}`,
        {}, // Empty body
        {
          headers: {
            'Authorization': `Bearer ${await this.getUserToken()}`
          }
        }
      );
      console.log(response);
      return response;
    } catch (error) {
      console.error('Error getting OTP:', error);
      throw error;
    }
  }

  // Method to verify OTP
  static async verifyOTP(collegeEmail:any, batchId:any, otp:any) {
    try {
      console.log(otp, collegeEmail, batchId);
      const response = await axios.post(
        `${this.API_BASE_URL}/user/verify_otp?collegeEmail=${collegeEmail}&batchId=${batchId}&otp=${otp}`, 
        {}, // Empty body
        {
          headers: {
            Authorization: `Bearer ${await this.getUserToken()}`
          }
        }
      );
      console.log(response.data);
      return response;
    } catch (error) {
      console.error('Error verifying OTP:', error);
      throw error;
    }
  }  

  // Method to get user's universities
  static async getUserUniversities() {
    try {
      const response = await axios.get(`${this.API_BASE_URL}/user/colleges`, {
        headers: {
          'Authorization': `Bearer ${await this.getUserToken()}`
        }
      });
      console.log('Universities:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error getting user universities:', error);
      return null;
    }
  }

  // Method to get batches
  static async getBatches() {
    try {
      const response = await axios.get(`${this.API_BASE_URL}/batch`, {
        headers: {
          'Authorization': `Bearer ${await this.getUserToken()}`
        }
      });
      console.log('Batches:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching batches:', error);
      throw error;
    }
  }

  // Method to get tasks status for user and college
  static async getTaskStatus(collegeId:any) {
    try {
      const response = await axios.get(`${this.API_BASE_URL}/tasks/user?collegeId=${collegeId}`, {
        headers: {
          'Authorization': `Bearer ${await this.getUserToken()}`
        }
      });
      console.log('Task Status:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching task status:', error);
      throw error;
    }
  }

  //Metod to mark task not completed
  static async toggleTaskStatus(taskId:any, collegeId:any) {
    try {
      console.log(taskId, collegeId);
      const response = await axios.post(`${this.API_BASE_URL}/tasks/user/toggle-status?taskId=${taskId}&collegeId=${collegeId}`, {}, {
        headers: {
          'Authorization': `Bearer ${await this.getUserToken()}`
        }
      });
      console.log('Task marked as not completed:', response.data);
    } catch (error) {
      console.error('Error marking task as not completed:', error);
      throw error;
    }
  }

  // Method to Update user's profile
  static async updateUser(userId:any, user:any) {

    try {
      const response = await axios.put(
        `${this.API_BASE_URL}/user/update?name=${userId}`, user,
        {
          headers: {
            'Authorization': `Bearer ${await this.getUserToken()}`
          }
        }
      );
      console.log(response);
      return response;
    } catch (error) {
      console.error('Error user Update:', error);
      throw error;
    }
  }
}

export default UserService;
