import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskService from '../services/TaskService'; // Assuming you have a TaskService for API calls
import UserService from "../services/UserService";
// import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
// import { auth, googleProvider } from '../config/FirebaseConfig';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const loaderGif = require('../assets/loader.gif');
const TaskDetails: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { taskId, taskName, videoUrl } = location.state || {};
    const [steps, setSteps] = useState<any>([]);
    const [subSteps, setSubSteps] = useState<any>({});
    const [selectedVideoUrl, setSelectedVideoUrl] = useState<any>(videoUrl);
    const [selectedStep, setSelectedStep] = useState<any>(null);
    const [selectedSubStep, setSelectedSubStep] = useState<any>(null);
    const [completedSteps, setCompletedSteps] = useState<any>([]);
    const [completedSubSteps, setCompletedSubSteps] = useState<any>({});
    const [expandedSteps, setExpandedSteps] = useState<any>({});
    const [userData, setUserData] = useState<any>(null);    
    const [university, setUniversity] = useState<any>(null);
    const [activeTab, setActiveTab] = useState('overview');
    // const [showLoginPrompt, setShowLoginPrompt] = useState<boolean>(false);
    
    useEffect(() => {
        const taskService = new TaskService();

        const fetchTasks = async () => {
            if (localStorage.getItem('user')) {
                try {
                    const currentUser = await UserService.getProfileData();
                    setUserData(currentUser);
                    const userUniversities = await UserService.getUserUniversities();
                    setUniversity(userUniversities[0]);
                } catch (error) {
                    console.error("Error fetching user or university data:", error);
                }
            }
            try {
                const { steps, subSteps } = await taskService.getAllTasksByParent(taskId);
                setSteps(Object.values(steps).flat() || []);
                setSubSteps(subSteps || {});

                // Set initial selected step and video URL
                if (steps && Object.values(steps).flat().length > 0) {
                    const initialStep:any = Object.values(steps).flat()[0];
                    setSelectedStep(initialStep);
                    setSelectedVideoUrl(initialStep.assetUrl || videoUrl);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };
        
        fetchTasks();
    }, [taskId, videoUrl]);

    const handleStepCompletionToggle = (stepId:any) => {
        setCompletedSteps((prevState:any) =>
            prevState.includes(stepId)
                ? prevState.filter((id:any) => id !== stepId)
                : [...prevState, stepId]
        );
    };

    const handleSubStepCompletionToggle = (subStepId:any) => {
        setCompletedSubSteps((prevState:any) => ({
            ...prevState,
            [subStepId]: !prevState[subStepId],
        }));
    };

    const handleSubStepSelection = (subStep:any) => {
        setSelectedSubStep(subStep);
        setActiveTab('overview');
        setSelectedVideoUrl(subStep.assetUrl || videoUrl);
    };

    const toggleExpandStep = (stepId:any) => {
        setExpandedSteps((prevState:any) => ({
            ...prevState,
            [stepId]: !prevState[stepId],
        }));
        const step:any = steps.find((step:any) => step.taskId === stepId);
        if (step) {
            setSelectedStep(step);
            setSelectedSubStep(null);
            setSelectedVideoUrl(step.assetUrl || videoUrl);
        }
    };

    const handlePrevNext = (direction:any) => {
        if (!selectedStep) return;
        const currentIndex = steps.findIndex((step:any) => step.taskId === selectedStep.taskId);
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < steps.length) {
            const newStep = steps[newIndex];
            setSelectedStep(newStep);
            setSelectedSubStep(null);
            setActiveTab('overview');
            setSelectedVideoUrl(newStep.assetUrl || videoUrl);
        }
    };

    const handlePrevNextSubStep = (direction:any) => {
        if (!selectedStep || !subSteps[selectedStep.taskId]) return;
        const currentSubSteps = subSteps[selectedStep.taskId];
        const currentIndex = currentSubSteps.findIndex((subStep:any) => subStep.taskId === selectedSubStep?.taskId);
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < currentSubSteps.length) {
            setSelectedSubStep(currentSubSteps[newIndex]);
            setActiveTab('overview');
        }
    };
    // const signInWithGoogle = async () => {
    //     try {
    //         const result = await signInWithPopup(auth, googleProvider);
    //         const credential:any = GoogleAuthProvider.credentialFromResult(result);
    //         const token = credential.idToken;

    //         const loggedInUser = {
    //             userId: result.user.uid,
    //             displayName: result.user.displayName,
    //             email: result.user.email,
    //             photoURL: result.user.photoURL,
    //         };

    //         await UserService.signInWithGoogle(token, loggedInUser);
    //         setUserData(loggedInUser);
    //         localStorage.setItem('user', JSON.stringify(loggedInUser));
    //         window.location.href = '/profile'; 
    //         // Redirect to profile page after successful login
    //         // navigate('/profile');
    //     } catch (error) {
    //         console.error('Google Sign-In Error:', error);
    //     }
    // };
    const addToast= (type:any,message:any)=>{
        if(type === 'error')
            toast.error(message);
        if(type === 'warning')
            toast.warning(message);
    };
    if (!steps.length) {
        return <div className='loader'><span><img src={loaderGif} alt="Loader"/></span></div>;
    }

    return (
        <><ToastContainer />
        <div className="container mx-auto p-6 mt-15 taskDetail">


            <button onClick={() => navigate('/')} className="mb-6 px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition duration-200 backToList">Back to Checklist</button>
            <div className="processBoxBar">
                <h1 className="text-3xl font-semibold mb-6 text-center">{taskName}</h1>
                <div className="w-full bg-gray-200 rounded-full h-6 mb-6">
                    <div className="bg-blue-500 h-6 rounded-full" style={{ width: `${(completedSteps.length / steps.length) * 100}%` }}>
                        <span className="text-white font-bold ml-2">
                            {Math.round((completedSteps.length / steps.length) * 100)}%
                        </span>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap md:flex-nowrap">
                <div className="w-full md:w-1/3 bg-blue-50 p-4 rounded-lg shadow-lg mb-6">
                    <div className='subListData'>
                        <h2 className="text-lg font-semibold text-blue-700 mb-4">List of Sub tasks</h2>
                        <ul>
                            {steps.map((step: any, index: number) => (
                                <li key={step.taskId} className={(index !== 0 && !(userData && university?.collegeName))?'mb-2 disabledList':'mb-2'}>
                                    <div className="flex items-center justify-between">
                                        <button
                                            onClick={() => toggleExpandStep(step.taskId)}
                                            className={`text-left w-full p-2 rounded-md hover:bg-blue-100 transition ${selectedStep?.taskId === step.taskId ? 'bg-blue-200' : 'bg-white'}`}
                                            disabled={index !== 0 && !(userData && university?.collegeName)}
                                        >
                                            {step.taskName}
                                        </button>
                                        {subSteps[step.taskId] && subSteps[step.taskId].length > 0 && (
                                            expandedSteps[step.taskId] ? (
                                                <FaCaretUp className="ml-2" />
                                            ) : (
                                                <FaCaretDown className="ml-2" />
                                            )
                                        )}
                                        {completedSteps.includes(step.taskId) && (
                                            <FaCheckCircle className="text-green-500 ml-2" />
                                        )}
                                    </div>
                                    {expandedSteps[step.taskId] && subSteps[step.taskId] && (
                                        <div className="ml-4 mt-2">
                                            {subSteps[step.taskId].map((subStep: any) => (
                                                <div
                                                    key={subStep.taskId}
                                                    className={`p-3 rounded-lg border ${selectedSubStep?.taskId === subStep.taskId
                                                            ? 'border-blue-500 bg-blue-50'
                                                            : 'border-gray-300 bg-white'} hover:bg-blue-50 transition cursor-pointer flex items-center justify-between`}
                                                    onClick={() => handleSubStepSelection(subStep)}
                                                >
                                                    <span>{subStep.taskName}</span>
                                                    {completedSubSteps[subStep.taskId] && (
                                                        <FaCheckCircle className="text-green-500" />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="w-full md:w-2/3 ml-0 md:ml-6">
                    <div className='rightScrollBar'>
                        <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
                            {selectedVideoUrl ?
                                <div className="video-player mb-4">
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src={selectedVideoUrl}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    ></iframe>
                                </div> : null}

                            <div className="tabs">
                                <div className="flex border-b">
                                    <button
                                        onClick={() => setActiveTab('overview')}
                                        className={`w-1/2 py-2 px-4 ${activeTab === 'overview' ? 'bg-blue-100' : 'bg-white'} border-b-2 border-blue-500`}
                                    >
                                        Overview
                                    </button>
                                    <button
                                        onClick={() => setActiveTab('faq')}
                                        className={`w-1/2 py-2 px-4 ${activeTab === 'faq' ? 'bg-blue-100' : 'bg-white'} border-b-2 border-blue-500`}
                                    >
                                        FAQ
                                    </button>
                                </div>
                                <div className="p-4 bg-white flex-grow">
                                    {activeTab === 'overview' && (
                                        <div>
                                            <h3 className="text-lg font-semibold mb-2">Overview</h3>
                                            <p dangerouslySetInnerHTML={{ __html: selectedSubStep ? selectedSubStep.blog : selectedStep?.blog }} />
                                        </div>
                                    )}
                                    {activeTab === 'faq' && (
                                        <div>
                                            <h3 className="text-lg font-semibold mb-2">FAQ</h3>
                                            <p dangerouslySetInnerHTML={{ __html: selectedSubStep ? selectedSubStep.faq : selectedStep?.faq }} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between items-center taskButton">
                            {selectedSubStep ? (
                                <>
                                    <button
                                        onClick={() => handlePrevNextSubStep(-1)}
                                        className={`py-2 px-4 rounded-lg ${subSteps[selectedStep.taskId]?.[0].taskId === selectedSubStep.taskId
                                                ? 'bg-gray-300 cursor-not-allowed'
                                                : 'bg-blue-500 text-white'}`}
                                        disabled={subSteps[selectedStep.taskId]?.[0].taskId === selectedSubStep.taskId}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={() => handleSubStepCompletionToggle(selectedSubStep.taskId)}
                                        className={`py-2 px-6 rounded-lg font-semibold ${completedSubSteps[selectedSubStep.taskId] ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}
                                    >
                                        {completedSubSteps[selectedSubStep.taskId] ? 'Undo' : 'Mark Complete'}
                                    </button>
                                    <button
                                        onClick={() => handlePrevNextSubStep(1)}
                                        className={`py-2 px-4 rounded-lg ${subSteps[selectedStep.taskId]?.[subSteps[selectedStep.taskId].length - 1].taskId === selectedSubStep.taskId
                                                ? 'bg-gray-300 cursor-not-allowed'
                                                : 'bg-blue-500 text-white'}`}
                                        disabled={subSteps[selectedStep.taskId]?.[subSteps[selectedStep.taskId].length - 1].taskId === selectedSubStep.taskId}
                                    >
                                        Next
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        onClick={() => handlePrevNext(-1)}
                                        className={`py-2 px-4 rounded-lg ${steps[0].taskId === selectedStep?.taskId ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                                        disabled={steps[0].taskId === selectedStep?.taskId}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (userData && university?.collegeName) {
                                                handleStepCompletionToggle(selectedStep?.taskId);
                                            } else {
                                                addToast('warning', 'Please add University to complete the task!');
                                                // setShowLoginPrompt(true);
                                            }
                                        } }
                                        className={`py-2 px-6 rounded-lg font-semibold ${completedSteps.includes(selectedStep?.taskId) ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}
                                    >
                                        {completedSteps.includes(selectedStep?.taskId) ? 'Undo' : 'Mark Complete'}
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (userData && university?.collegeName) {
                                                handlePrevNext(1);
                                            } else {
                                                addToast('warning', 'Please add University to complete the task!');
                                                // setShowLoginPrompt(true);
                                            }
                                        } }
                                        className={`py-2 px-4 rounded-lg ${steps[steps.length - 1].taskId === selectedStep?.taskId ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                                        disabled={steps[steps.length - 1].taskId === selectedStep?.taskId}
                                    >
                                        Next
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* {showLoginPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center loginPop">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-xs">
                <p className="text-lg font-semibold mb-4">Please log in to mark tasks as complete</p>
                <button className="py-2 px-6 bg-blue-500 text-white rounded-md font-semibold shadow-md w-full" onClick={signInWithGoogle}>
                Login with Google
                </button>
                <button
                    onClick={() => setShowLoginPrompt(false)}
                    className="mt-4 text-sm text-gray-500"
                >
                    Cancel
                </button>
            </div>
        </div>
    )} */}
        </div></>
    );
};

export default TaskDetails;