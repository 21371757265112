import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const rootdata:any = document.getElementById('root');
const root = ReactDOM.createRoot(rootdata);
root.render(
  <App />
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);
