import React, { useEffect, useState } from 'react';
import { FaUser, FaUniversity, FaCheckCircle, FaEnvelope, FaPencilAlt, FaSave } from 'react-icons/fa';
import Modal from '../components/Modal';
import UserService from '../services/UserService';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Profile: React.FC = () => {
  const [profile, setProfile] = useState<any>(null);
  const [universities, setUniversities] = useState<any>([]);
  const [university, setUniversity] = useState<any>({ collegeId: '', collegeName: '', userCollegeEmailId: '', collegeAddress: "" });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [emailToVerify, setEmailToVerify] = useState<any>('');
  const [otp, setOtp] = useState<any>('');
  const [isOTPSent, setIsOTPSent] = useState<boolean>(false);
  const [isUserEdit, setIsUserEdit] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedBatch, setSelectedBatch] = useState<any>('');
  const [batches, setBatches] = useState<any>([]);
  const [otpError, setOtpError] = useState<any>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      const loggedIn = await UserService.isUserLoggedIn();
      if (loggedIn) {
        try {
          const profileData = await UserService.getProfileData();
          setProfile(profileData);
          fetchUniversitiesAndBatches();
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      } else {
        navigate('/'); // Redirect to home if not logged in
      }
    };

    const fetchUniversitiesAndBatches = async () => {
      try {
        const universityData = await UserService.getUserUniversities();
        setUniversities(Object.values(universityData) || []);
      } catch (error) {
        console.error('Error fetching university data:', error);
      }

      try {
        const batchData = await UserService.getBatches();
        setBatches(batchData || []);
      } catch (error) {
        console.error('Error fetching batch data:', error);
      }
    };

    fetchProfileData();
  }, [navigate]);

  const handleUniversityChange = (e:any) => {
    const { name, value } = e.target;
    setUniversity({ ...university, [name]: value });
  };

  const handleBatchChange = (e:any) => {
    setSelectedBatch(e.target.value);
  };

  const verifyOTP = () => {
    return new Promise((resolve) => {
      if (otp) {
        const id = batches.find((batch:any) => batch.batchName === selectedBatch)?.id;

        UserService.verifyOTP(university.userCollegeEmailId, id, otp)
          .then(() => {
            setOtpError(''); // Clear error on success
            resolve(true); // Verification successful
            addToast('success','OTP verified successfully!');
          })
          .catch((error:any) => {
            console.error('Error verifying OTP:', error);
            setOtpError('Wrong OTP'); // Set error message
            resolve(false); // Verification failed
          });
      } else {
        setOtpError('Invalid OTP'); // Set error if OTP is empty
        resolve(false); // Invalid OTP
      }
    });
  };

  const addUniversity = () => {
    if (university.userCollegeEmailId) {
      setEmailToVerify(university.userCollegeEmailId);
      setIsModalOpen(true);
      setOtpError(''); // Reset error when opening modal
    }
  };

  const handleVerifyAndAdd = () => {
    verifyOTP().then((result) => {
      if (result) {
        UserService.getUserUniversities()
          .then((universityData) => {
            setUniversities(Object.values(universityData) || []);
          })
          .catch((error) => {
            console.error('Error fetching university data:', error);
          });
        closeModal();
      }
    }).catch((error) => {
      setOtpError(error);
      console.error('Error verifying OTP:', error);
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEmailToVerify('');
    setOtp('');
    setOtpError(''); // Clear error message on close
  };

  const getOTP = () => {
    UserService.getOTP(emailToVerify)
      .then(() => {
        setIsOTPSent(true);
        setOtpError(''); // Clear any previous errors
        addToast('success','OTP send successfully!');
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
      });
  };

  const resendOTP = () => {
    UserService.getOTP(emailToVerify)
      .then(() => {
        setIsOTPSent(true);
        setOtpError(''); // Clear any previous errors
        addToast('success','OTP resend successfully!');
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
      });
  };
  const addToast= (type:any,message:any)=>{
      if(type === 'error')
          toast.error(message);
      if(type === 'warning')
          toast.warning(message);
      if(type === 'success')
          toast.success(message);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  const updateUser=async()=>{
    let data = {
      creationTime: profile.creationTime,
      displayName: inputValue,
      email: profile.email,
      gender: profile.gender,
      loggedIn: profile.loggedIn,
      photoURL: profile.photoURL,
      token: profile.token,
      universities: profile.universities
    }
    const updateUser = await UserService.updateUser(profile.email,data);
    const profileuData = {
      userId: profile.userId,
      displayName: updateUser.data.displayName,
      email: updateUser.data.email,
      gender: updateUser.data.gender || "",
      photoURL: updateUser.data.photoURL,
      creationTime: updateUser.data.creationTime,
      token: profile.token,
      loggedIn: true,
    };
    localStorage.setItem("profileData", JSON.stringify(profileuData));
    const profileData = await UserService.getProfileData();
    setProfile(profileData);
    setIsUserEdit(false);
    addToast('success','User Name Updated Successfully!');
  }

  if (!profile) {
    return (
      <div className="container mx-auto flex flex-col items-center justify-center min-h-screen p-4">
        <h2 className="text-3xl font-bold text-blue-800 mb-6">Please Log In</h2>
        <p className="text-lg text-gray-700 text-center mb-6 max-w-lg">
          We're excited to help you on your journey to the US! Please log in to view your profile and access all our features.
          After logging in and verifying your university email, you’ll be able to chat with peers/alumni and track your progress.
        </p>
      </div>
    );
  }

  return (
   <><ToastContainer />
   <div className="container mx-auto px-4 mt-24 mobMarTop">
      <div className="isolate mt-44 mb-36 p-5 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl mobPaddZero">
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={closeModal} title={"Verify University Email"}>
            {!isOTPSent ? (
              <div className="uniModelBox">
                <div className='borderBox'>
                  <p>Send OTP to {emailToVerify}?</p>
                  <label className="block mt-4">
                    <span className="text-blue-700 font-semibold">Select Batch:</span>
                    <select
                      value={selectedBatch}
                      onChange={handleBatchChange}
                      className="mt-2 p-2 border border-gray-300 rounded-md w-full"
                    >
                      <option value="" disabled>Select Batch</option>
                      {batches.map((batch: any) => (
                        <option key={batch.id} value={batch.batchName}>
                          {batch.batchName}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="mt-4 flex justify-end gap-4">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 order2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={getOTP}
                    className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800 order1"
                    disabled={!selectedBatch}
                  >
                    Send OTP
                  </button>
                </div>
              </div>
            ) : (
              <div className="uniModelBox">
                <div className='borderBox otpInput'>
                  <p>We've Just Emailed A OTP To<br /> {emailToVerify}  (Batch: {selectedBatch})<br />Enter That Code Below::</p>
                  {otpError && <p className="text-red-600">{otpError}</p>}
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="mt-2 px-4 py-2 border rounded-md w-full"
                    placeholder="Enter OTP" />
                  <button onClick={resendOTP} className="resendOtp">
                    Resend OTP
                  </button>
                  <span className='otpCount'>00:00</span>
                </div>
                <div className="mt-4 flex justify-end gap-4">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleVerifyAndAdd}
                    className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800"
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            )}
          </Modal>
        )}

        <div className="max-w-xl text-center mx-auto lg:max-w-2xl mb-12 profileInfoBox">
          <h2 className="mb-6">
            <span className="relative inline-block">
              <span className="relative text-primary title">Profile Information</span>
            </span>
          </h2>
          <div className="flex justify-center mt-5">
            {profile.photoURL ? (
              <img src={profile.photoURL} alt="Profile" className="rounded-full border-2 border-blue-700 w-24 h-24" />
            ) : (
              <div className="w-24 h-24 rounded-full bg-blue-50 flex items-center justify-center">
                <FaUser className="text-blue-700 w-12 h-12" />
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="block mb-4">
              <span className="text-blue-700 font-semibold flex items-center">
                <FaUser className="mr-2" /> Name:
              </span>
              {isUserEdit? (
                  <input type="text" name="name" value={inputValue ? inputValue: profile.displayName} onChange={handleInputChange}
                  className='mt-2 p-2 border border-gray-300 rounded-md w-full editBox' />
              ): (
                <input type="text" name="name" value={profile.displayName} readOnly
                className='mt-2 p-2 border border-gray-300 rounded-md w-full' />
              )}
                <button className='edit' onClick={()=>{if(isUserEdit)updateUser(); else setIsUserEdit(true)}}>
                  {isUserEdit?(
                    <FaSave className="mr-2" />
                  ):(
                    <FaPencilAlt className="mr-2" />
                  )}
                  </button>
            </label>
            <label className="block mb-4">
              <span className="text-blue-700 font-semibold flex items-center">
                <FaEnvelope className="mr-2" /> Email:
              </span>
              <input
                type="email"
                name="email"
                value={profile.email}
                readOnly
                className="mt-2 p-2 border border-gray-300 rounded-md w-full" />
            </label>
            <div className="mt-8">
              <h2 className="text-2xl font-bold text-blue-800 mb-4">University Admits</h2>
              <div className="flex items-center gap-2 uniBox">
                <input
                  type="email"
                  name="userCollegeEmailId"
                  value={university.userCollegeEmailId}
                  onChange={handleUniversityChange}
                  placeholder="University Email"
                  className="p-2 border border-gray-300 rounded-md flex-1" />
                <button
                  onClick={addUniversity}
                  className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                >
                  Add University
                </button>
              </div>
              <ul className="mt-4 listOfUni">
                {universities.length > 0 && universities.map((uni: any, index: any) => (
                  <li key={index} className="flex items-center justify-between p-2 border-b">
                    <div className="flex items-center name">
                      <FaUniversity className="mr-2" />
                      <span className='uniName'>University Name</span>
                      <span>{uni.collegeName}</span>
                    </div>
                    <div className="flex items-center email">
                      <FaCheckCircle className="text-green-600 mr-2" />
                      <span className='uniEmail'>Email Address</span>
                      <span>{uni.userCollegeEmailId}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
};

export default Profile;
